<template>
  <div>
    <!-- 第一行 -->
    <div class="top">
      <div class="top2">
        <div class="topleft">
          <router-link to="/">
            <img src="@/assets/images/toplogo.png" alt="">
          </router-link>
        </div>
        <div class="topright">
          <div v-if="!token" class="tkshow">
            <div class="two" @click="showModal1">{{ $t('login') }}
              <a-modal v-model="visible1" width="390px">
                <div>
                  <a-tabs default-active-key="1" @change="callback">
                    <a-tab-pane key="1" :tab="$t('supplier')">
                      <div class="list">
                          <div class="one">{{ $t('Mobile.email') }}</div>
                          <div class="account"><input type="text" v-model="accountname" @focus="qcac()"></div>
                          <div class="acone" v-show="tag">{{ $t('Account.number') }}</div>
                          <div v-if="loginType == 1">
                            <div class="two2">{{ $t('code') }}</div>
                            <div class="code">
                                <input type="text" v-model="code">
                                <button  type="button" class="ant-btn"  @click="() => {
                                e => e.preventDefault()
                                sendCode()
                                }"><span>{{count}}</span></button>
                            </div>
                          </div>
                          <div v-else>
                            <div class="two2">{{ $t('Password') }}</div>
                            <div class="account"><input type="password" v-model="password"></div>
                          </div>
                          <div class="logintype" @click="checktype()">{{ loginType == 1 ? $t('Passwordlogin') : $t('Vcodelogin')  }}>></div>
                          <button class="submit" @click="regsub()">{{ $t('login') }}</button>
                          <div class="acone" v-show="subtag">{{ $t('correctly') }}</div>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" :tab="$t('buyers')" force-render>
                       <div class="list">
                          <div class="one">{{ $t('Mobile.email') }}</div>
                          <div class="account"><input type="text" v-model="accountname" @focus="qcac()"></div>
                          <div class="acone" v-show="tag">{{ $t('Account.number') }}</div>
                          <div v-if="loginType == 1">
                            <div class="two2">{{ $t('code') }}</div>
                            <div class="code">
                                <input type="text" v-model="code">
                                <button  type="button" class="ant-btn"  @click="() => {
                                e => e.preventDefault()
                                sendCode()
                                }"><span>{{count}}</span></button>
                            </div>
                          </div>
                          <div v-else>
                            <div class="two2">{{ $t('Password') }}</div>
                            <div class="account"><input type="password" v-model="password"></div>
                          </div>
                          <div class="logintype" @click="checktype()">{{ loginType == 1 ? $t('Passwordlogin') : $t('Vcodelogin')  }}>></div>
                          <button class="submit" @click="regsub()">{{ $t('login') }}</button>
                          <div class="acone" v-show="subtag">{{ $t('correctly') }}</div>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </a-modal>
            </div>
            <!-- <div><router-link to="/Login">{{ $t('supplier')}}</router-link></div>
            <div class="two"><router-link to="/Login">{{ $t('buyers')}}</router-link></div> -->
          </div>
          <div class="tkshow" v-if="token">
            <div>{{account}}</div>
            <div class="two" @click="loginout()">{{ $t('signout') }}</div>
            <div class="two"><router-link :to="{ name: 'UsersIndex', params: {userId: userId }}">{{ $t('userinfo')}}</router-link></div>
          </div>
          <div  class="two">
            <a-dropdown>
              <span class="ant-dropdown-link" @click="e => e.preventDefault()">
              {{ $t('signUp') }}<a-icon type="down" />
              </span>
              <a-menu slot="overlay">
                <a-menu-item>
                  <router-link  :to="{ name: 'Register', params: {categoryId: 1 }}">{{ $t('signUp.supplier') }}</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link  :to="{ name: 'Register', params: {categoryId: 2 }}">{{ $t('signUp.buyer') }}</router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div  class="two">
            <!-- <a-dropdown>
              <span class="ant-dropdown-link">
              {{ $t('lang.language')}}<a-icon type="down" />
              </span>
              <a-menu slot="overlay">
                <a-menu-item @click="setLang('en-US')">
                  <a href="javascript:;">{{ $t('lang.english') }}</a>
                </a-menu-item>
                <a-menu-item @click="setLang('zh-CN')">
                  <a href="javascript:;">{{ $t('lang.chinese') }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown> -->
             <img src="@/assets/images/chtag.png" alt="" height="25px" style="border-top-left-radius:5px;border-bottom-left-radius:5px;" @click="setLang('zh-CN')">
            <img src="@/assets/images/entag.png" alt="" height="25px" style="border-top-right-radius:5px;border-bottom-right-radius:5px;" @click="setLang('en-US')">
          </div>
        </div>
      </div>
    </div>
    <!-- 第二行 -->
    <div class="search">
      <div class="logo">
        <div class="logoimg">
          <img :src="conmpany.logo" alt="">
        </div>
        <div class="sinfo">
          <div class="info1">{{conmpany.companyName}}</div>
          <div class="info2">
            <div class="linfo">{{$t('MainProducts')}}：</div>
            <div class="rinfo">{{conmpany.mainProducts}}</div>
          </div>
          <div class="info2">
            <div class="linfo">{{$t('TradeTerms')}}：</div> 
            <div class="rinfo">{{conmpany.tradeTerms}} </div>
          </div>
        </div>
        <div class="searchinput">
          <input type="text" v-model="keywords" :placeholder="$t('search.kewords')">
          <a
            @click="() => {
              e => e.preventDefault()
              searchBtn()
            }"
            >
               <router-link :to="{ name: 'SupplierProductSearch', params: { keywords: keywords, id:id } }" style="color: #F3F3F3">{{ $t('search') }}</router-link>
            </a>
        </div>
      </div>
    </div>
    <!-- 第三行 -->
    <div class="cate">
      <div class="gory">
        <div class="cleft">
          <div :class="cateIsShow ? 'ctwo' : (routerName == 'Supplier' ? 'cone active' : 'cone') ">
            <router-link :to="{ name: 'Supplier', params: { id: id, exhibitionId: exhibitionId } }">
              {{ $t('home') }}
            </router-link>
          </div>
          <div :class="(cateIsShow || routerName == 'SupplierProduct') ? 'ctwo active' : 'ctwo' " 
            @mouseenter="cateIsShow=true" @mouseleave="cateIsShow=false">
            <!-- <router-link to="/supplierproduct"> -->
              <a-icon type="unordered-list" />&nbsp;
              <router-link :to="{ name: 'SupplierProduct', params: { id: id, exhibitionId: exhibitionId } }">{{ $t('products') }}</router-link>
            <!-- </router-link> -->
            <template v-if="cateList.length > 0">
              <div class="pcate" v-show="cateIsShow">
                <div class="pcate1" v-for="(item,index) in cateList" :key="index">
                  <router-link :to="{ name: 'SupplierProduct', params: { id: id,categoryId: item.categoryId, exhibitionId: exhibitionId } }">{{item.categoryName}}</router-link>
                </div>
              </div>
            </template>
          </div>
          <div :class="cateIsShow ? 'ctwo' : (routerName == 'VirtualMeeting' ? 'ctwo active' : 'ctwo') ">
            <router-link :to="{ name: 'VirtualMeeting', params: { id: id, exhibitionId: exhibitionId } }">
              {{ $t('slive') }}
            </router-link>
          </div>
          <div :class="cateIsShow ? 'ctwo' : (routerName == 'SupplierAbout' ? 'ctwo active' : 'ctwo') ">
            <router-link  :to="{ name: 'SupplierAbout', params: { id: id, exhibitionId: exhibitionId } }">
              {{ $t('about') }}
            </router-link>
          </div>
          <div :class="cateIsShow ? 'ctwo' : (routerName == 'ContactUs' ? 'ctwo active' : 'ctwo') ">
            <router-link :to="{ name: 'ContactUs', params: { id: id, exhibitionId: exhibitionId} }">
              {{ $t('ContactUs') }}
            </router-link>
          </div>
        </div>
        <div class="cright">
          <div class="cright1">
            <div class="crimg1">
               <img src="@/assets/images/me.png" alt="">
            </div>
            <div class="crtxt1">
              <router-link :to="{ name: 'Talking' ,params: { adminId: conmpany.adminId } }">
              {{ $t('chat') }}
              </router-link>
            </div>
          </div>
          <div class="cright2">
            <div class="crimg2">
              <img src="@/assets/images/bo.png" alt="">
            </div>
            <div class="crtxt2" @click="showModal">{{ $t('meeting') }}</div>
            <a-modal v-model="visible"   width="1060px">
                <div class="mtitle">{{ $t('meeting') }}</div>
                <div class="mtitle2">Shanghai Marya Pharmaceutical Engineering & Project Co., Ltd.</div>
                <div class="minfo">
                  <div class="minfo1">
                    <div class="tag"></div>
                    <div class="mleft1">{{ $t('ChooseExhibition') }}</div> 
                  </div>
                  <div class="mradio">
                    <div>
                      <a-radio-group :options="exhibitionList" :default-value="value1" @change="onChange1" />
                    </div>
                  </div>
                  <div class="infolist" v-if="tag" style="margin-top:36px" v-show="isRegist==0">
                    <div class="minfo1">
                      <div class="tag"></div>
                      <div class="mleft1">{{ $t('regist') }}
                        <span>{{ $t('register.first') }}</span>
                      </div> 
                    </div>
                    <div class="minfo2">
                      <div class="mitem1">{{ $t('company.name') }}</div>
                      <div class="mitem2">{{showDatas.companyName}}</div>
                    </div>
                    <div class="minfo2">
                      <div class="mitem1">{{ $t('area') }}</div>
                      <div class="mitem2">{{showDatas.area}}</div>
                    </div>
                    <div class="minfo2">
                      <div class="mitem1">{{ $t('address') }}</div>
                      <div class="mitem2">{{showDatas.address}}</div>
                      <div class="mitem3">{{ $t('postalcode') }}</div>
                      <div class="mitem4">{{showDatas.postCode}}</div>
                    </div>
                    <div class="minfo2">
                      <div class="mitem1">{{ $t('contact') }}</div>
                      <div class="mitem2">{{showDatas.concat}}</div>
                      <div class="mitem3">{{ $t('title') }}</div>
                      <div class="mitem4">{{showDatas.title}}</div>
                    </div>
                    <div class="minfo2">
                      <div class="mitem1">{{ $t('mobile.number') }}</div>
                      <div class="mitem2">{{showDatas.mobile}}</div>
                      <div class="mitem3">{{ $t('email') }}</div>
                      <div class="mitem4">{{showDatas.email}}</div>
                    </div>
                    <div class="minfo3">
                      <div class="minfo4">{{ $t('telephone') }}</div>
                      <div class="minfo5"><input type="text" placeholder="please enter" /></div>
                      <div class="minfo6">{{ $t('fax') }}</div>
                      <div class="minfo7"><input type="text" placeholder="please enter" /></div>
                    </div>
                    <div class="minfo3">
                      <div class="minfo4">{{ $t('WeChat') }}</div>
                      <div class="minfo5"><input type="text" placeholder="please enter" /></div>
                      <div class="minfo6">{{ $t('whatsApp') }}</div>
                      <div class="minfo7"><input type="text" placeholder="please enter" /></div>
                    </div>
                    <div class="minfo3">
                      <div class="minfo4">{{$t('Industry.type')}}</div>
                      <div class="minfo8">
                        <a-checkbox-group v-model="categoryIds">
                          <a-checkbox :value="item.categoryId" v-for="(item,index) in categoryList" :key="index">
                            {{item.categoryName}}
                          </a-checkbox>
                          <a-checkbox value="0" @click="checked()">{{ $t('other') }}</a-checkbox>
                        </a-checkbox-group>
                      </div>
                    </div>
                    <div class="minfo3" v-show="checktag">
                      <div class="minfo4">{{$t('othercate')}}</div>
                      <div class="minfo5"><input type="text" :placeholder="$t('please.enter')"  v-model="other" /></div>
                    </div>
                  </div>
                  <div class="minfo1" style="margin-top:40px">
                    <div class="tag"></div>
                    <div class="mleft1">{{ $t('AppointmentTime') }}</div> 
                  </div>
                  <div class="setime">
                    <a-range-picker @change="handleChange2" format="YYYY-MM-DD HH:mm:ss"  
                     :show-time="{
                        hideDisabledOptions: true,
                       
                      }">
                      <template slot="renderExtraFooter">
                      </template>
                    </a-range-picker>
                  </div>
                  <div class="minfo3">
                    <button class="submit" @click="regsub2()" style="margin:0 auto;">{{ $t('submit') }}</button>
                  </div>
                </div>
              </a-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from 'store'

const plainOptions = ['2012Guangdong Export Online Fari1', '2012Guangdong Export Online Fari2', '2012Guangdong Export Online Fari3','2012Guangdong Export Online Fari4'];
const options = [
  { label: '2012Guangdong Export Online Fari', value: '2012Guangdong Export Online Fari' }
];
const optionsWithDisabled = [
  { label: '2012Guangdong Export Online Fari', value: '2012Guangdong Export Online Fari' }
];

export default {
  inject: ['reload'], // 注入依赖
  props: {
    id: {
      type: String,
      default: ''
    },
    exhibitionId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
        language: '',
        logoimg: require('@/assets/images/logo1.png'),
        keywords: '',
        queryParam: { status: 0 },
        routerName: this.$router.currentRoute.name,
        cateIsShow: false,
        visible: false,
        loading: false,
        token: localStorage.token,
        account: localStorage.account,
        userId: localStorage.userId,
        //单选按钮组
        plainOptions,
        options,
        optionsWithDisabled,
        value1: '2012Guangdong Export Online Fari',
        tag: true,
        conmpany: [],
        cateList: [],
        exhibitionList: [],
        showDatas: [],
        isRegist: 1,
        startTime: '',
        endTime: '',
        categoryList: [],
        telephone: '',
        concat: '',
        wechat: '',
        whatsapp: '',
        categoryIds: [],
        checktag: false,
        other: '',
        accountname: '',
        loading1: false,
        visible1: false,
        count: '发送验证码',
        categoryId: 1,
        show: true,
        timer: null,
        tag: false,
        subtag: false,
        code: '',
        logo: '',
        loginType: 2,
        password: ''
    }
  },
  mounted () {
    if (storage.get('lang')) this.language = storage.get('lang')
    else this.language = 'en-US'
    this.language = this.$t('lang.english')
    this.getConmpany()
    this.getList()
    this.getExhibitionList()
    this.getCategoryList()
    this.getAbout()
    this.isLogin()
  },
  methods: {
   setLang (lang) {
      if (lang != this.language) {
        this.$store.dispatch('setLang', lang)
        this.$nextTick(() => {
          this.reload()
        })
      }
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      
    },
    regsub2() {
      this.loading = true;
      if(this.startTime == '' || this.endTime == '') {
        this.visible = false;
        this.$layer.alert('时间不能为空');
        return
      }
      if (this.isRegist == 1) {
        console.log(this.exhibitionId)
        const params = {
           exhibitionId: this.exhibitionId,
           isregist: this.isRegist,
           regist: [],
           companyId: this.id,
           startTime: this.startTime,
           endTime: this.endTime
        }
        this.$common.handlePost('/company/meeting/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert('报名成功');
        } else {
            this.$layer.alert(data.msg);
        }
        }).catch(() => {
          // this.$notification.error({
          // message: '错误',
          // description: '请求信息失败，请重试'
          // })
        })
      } else {
        console.log(0)
        const categoryIds2 = []
        this.categoryIds.forEach(item =>{
          if (item  != 0) {
            categoryIds2.push(
              {'categoryId': item, 'isOther': 0}
            )
          } else {
            categoryIds2.push(
              {'categoryId': 0, 'isOther': 1, 'desc': this.other}
            )
          }
        })
        const params = {
           exhibitionId: this.exhibitionId,
           isregist: this.isRegist,
           regist: {
              telephone: this.telephone,
              concat: this.concat,
              wechat: this.wechat,
              whatsapp: this.whatsapp,
              categoryIds: categoryIds2
           },
           companyId: this.id,
           startTime: this.startTime,
           endTime: this.endTime
        }
        this.$common.handlePost('/company/meeting/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert('报名成功');
        } else {
            this.$layer.alert(data.msg);
        }
        }).catch(() => {
          // this.$notification.error({
          // message: '错误',
          // description: '请求信息失败，请重试'
          // })
        })
      }
      setTimeout(() => {
        this.visible = false;
        this.loading = false;
      }, 2000);
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 搜索
    searchBtn () {
      console.log(this.keywords)
    },
    onChange(date, dateString) {
      console.log(date, dateString);
    },
    //单选按钮
    onChange1(e) {
      this.exhibitionId =  e.target.value
      // 采购商是否加入展会
      this.$common.fetchList('/company/meeting/isregist?exhibitionId='+this.exhibitionId, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.isRegist = result.isRegist
          this.showDatas = result.showDatas
          console.log(this.showDatas)
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    handleChange2(value, mode) {
      this.startTime = mode[0]
      this.endTime = mode[1]
    },
    // 获取公司详情
    getConmpany () {
      this.$common.fetchList('/company/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.conmpany = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 获取行业分类
    getList () {
       this.$common.fetchList('/exhibition/category/lists?parentId=0', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.cateList = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    //供应商已报名的展会列表
    getExhibitionList () {
       this.$common.fetchList('/company/joined/exhibition/lists?companyId='+this.id, {}).then(data => {
          if (data.code === 200) {
            const result = data.data
            result.forEach(item =>{
            this.exhibitionList.push(
              {'label': item.exhibitionName, 'value': item.exhibitionId}
            )
          })
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
   //CheckBox
   onChange(e) {
     this.value = e.target.value
      console.log('radio checked', e.target.value);
    },
    //供应商包含的行业
    getCategoryList () {
       this.$common.fetchList('/company/category/lists', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.categoryList = result
        } 
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    checked() {
      this.checktag = !this.checktag
    },
     showModal1() {
       this.visible1 = true;
    },
    callback(key) {
      this.categoryId = key
      console.log(this.categoryId);
    },
     sendCode(){
      if (this.accountname == ''){
        this.tag = true;
        return ;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            }
        }, 1000)
      }
      const params = {
          account: this.accountname,
          type: 'login',
      }
      this.$common.handlePost('/code/send', params).then(data => {
        if (data.code === 200) {
          // this.$layer.alert(data.msg);
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    //去除提示信息
    qcac() {
        this.tag = false
        this.subtag = false
    },
    //登录
    regsub() {
        if (this.loginType == 1){
          if (this.accountname == '' || this.code == '') {
              this.subtag = true
              return 
          }
        } else {
           if (this.accountname == '' || this.password == '') {
              this.subtag = true
              return 
          }  
        }
        const params = {
              account: this.accountname,
              password: this.password,
              categoryId: this.categoryId,
              loginType: this.loginType,
              code: this.code
        }
        this.$common.handlePost('/user/login', params).then(data => {
        if (data.code === 200) {
            console.log(data)
            this.$layer.alert(data.msg);
            localStorage.setItem('token',data.data.token)
            localStorage.setItem('account',data.data.account)
            localStorage.setItem('userId',data.data.userId)
            localStorage.setItem('categoryId',data.data.categoryId)
            localStorage.setItem('companyId',data.data.companyId)
             this.reload()
            // storage.set(ACCESS_TOKEN, data.data.token, 7 * 24 * 60 * 60 * 1000)
            // storage.set('account', data.data.account, 7 * 24 * 60 * 60 * 1000)
            // storage.set('userId', data.data.userId, 7 * 24 * 60 * 60 * 1000)
            // this.$router.push({name: 'Home'})
        } else {
            // this.$layer.alert(data.msg);
            this.$layer.msg(data.msg)
            this.accountname = ''
            this.password = ''
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
      setTimeout(() => {
        this.visible = false;
        this.loading = false;
      }, 1000);
    },
    //退出
    loginout() {
      this.$common.handleGet('/user/logout', {}).then(data => {
        if (data.code === 200) {
          localStorage.setItem('token','')
          this.$layer.alert(data.msg);
          this.reload()
        }
      }).catch(() => {
        this.$layer.alert(data.msg);
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    getAbout () {
      this.$common.fetchList('/index/about/us', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.logo = result.site_logo
        }
      }).catch(() => {
        // this.$notification.error({
        //   message: '错误',
        //   description: '请求信息失败，请重试'
        // })
      })
    },
    //判断token是否失效
    isLogin () {
       this.$common.fetchList('/user/is/login?token='+this.token, {}).then(data => {
        if (data.code !== 200) {
           localStorage.setItem('token','')
            localStorage.setItem('account','')
            localStorage.setItem('userId','')
            localStorage.setItem('categoryId','')
            localStorage.setItem('companyId','')
        }
      }).catch(() => {
        // this.$notification.error({
        //   message: '错误',
        //   description: '请求信息失败，请重试'
        // })
      })
    },
    //更改登录方式
    checktype() {
      if (this.loginType == 2) {
        this.loginType = 1
      } else{
        this.loginType = 2
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/spheader'; 
</style>